import React from "react"
import { Footer } from "components/footer"
import { AsideForm } from "components/aside-form"

export const AsideLayout = ({ children, lang = "" }) => {
  return (
    <div className="flex">
      <div className="componentest w-full md:w-9/12 pb-24 md:pb-0">
        {children}
        <Footer lang={lang} />
      </div>
      <div className="hidden md:block md:w-3/12 md:fixed md:right-0 md:top-0">
        <AsideForm />
      </div>
    </div>
  )
}
