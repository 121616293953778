import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

export const AsideForm = () => {
  useEffect(() => {
    initializeHubspotForm()
  })

  const initializeHubspotForm = () => {
    if ("hbspt" in window) {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "8283520",
        formId: "22ec5718-ab3c-44d4-8ea5-5e381d7e9f20",
        target: "#formContainer",
      })
    } else {
      setTimeout(initializeHubspotForm, 500)
    }
  }

  return (
    <div>
      <Helmet>
        <script
          charset="utf-8"
          type="text/javascript"
          src="//js.hsforms.net/forms/shell.js"
        ></script>
      </Helmet>
      <div className="following-form w-full bg-secondary md:h-screen flex items-center justify-center">
        <div id="formContainer"></div>
      </div>
    </div>
  )
}
