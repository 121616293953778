import React from "react"

export const FollowBtn = () => {
  return (
    <div className="follow-btn">
      <a
        className="ga-download-top btn--brand"
        href="https://hub.park-lot.com/document-download"
        target="_blank"
        rel="noreferrer"
      >
        資料ダウンロード　＞
      </a>
    </div>
  )
}
