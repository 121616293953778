import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"

export const Service = () => {
  return (
    <>
      <section className="hs__service">
        <div className="hs__container">
          <div className="hs__heading hs__heading--sm">
            <h2 className="hs__title">Service</h2>
            <p className="hs__subtitle">PARKLoTのサービス</p>
            <p className="hs__lead">
              開催可能なその他のSNSキャンペーンは
              <br />
              低コスト＆最短1日で実施可能です。
            </p>
          </div>
          <div className="hs__service-container">
            <div className="hs__service-card">
              <h3 className="hs__service-card-title">
                フォロー＆リツイートキャンペーン
              </h3>
              <p className="hs__service-card-text">
                Twitterのフォロー＆リツイートで抽選に参加するキャンペーンです。最短1日で実施が可能です。
              </p>
              <StaticImage
                layout="constrained"
                alt="フォロー＆リツイートキャンペーン"
                src="../images/product/f-rt-bnr.png"
              />
            </div>
            <div className="hs__service-card">
              <h3 className="hs__service-card-title">スクラッチ</h3>
              <p className="hs__service-card-text">
                スクラッチを削ることで抽選結果を見るキャンペーンです。マウスやタップで削る体験ができ、参加者にワクワク感を演出できます。
              </p>
              <StaticImage
                layout="constrained"
                alt="スクラッチキャンペーン"
                src="../images/product/scratch-bnr.png"
              />
            </div>
            <div className="hs__service-card">
              <h3 className="hs__service-card-title">スロット</h3>
              <p className="hs__service-card-text">
                スロットを回して抽選結果を見るキャンペーンです。結果が分かるまでのドキドキ感を演出できます。
              </p>
              <StaticImage
                className="hs__service-card-1col_img"
                layout="constrained"
                alt="スロットキャンペーン"
                src="../images/product/slot-bnr.png"
              />
            </div>
            <div className="hs__service-card">
              <h3 className="hs__service-card-title">クイズ＆診断</h3>
              <p className="hs__service-card-text">
                クイズ＆診断で参加するキャンペーンです。診断結果をTwitterにシェアすることで更に認知拡大を図ることが可能です。
              </p>
              <StaticImage
                className="hs__service-card-1col_img"
                layout="constrained"
                alt="クイズ・診断キャンペーン"
                src="../images/product/quiz-bnr.png"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="hs__contact">
        <div className="hs__contact-inner">
          <h2 className="hs__contact-title">
            すぐに、手軽に実施できる
            <br />
            SNSマーケティングツール
          </h2>
          <p className="hs__contact-lead">
            キャンペーンの企画内容などのご相談に関しても、
            <br className="hs__contact-lead-br" />
            承っております。
            <br />
            お気軽にご連絡ください。
          </p>
          <div className="hs__contact-cv">
            <div className="hs__contact-cv-form">
              <a
                className="form-btn"
                href="https://hub.park-lot.com/free-consulting"
                target="_blank"
                rel="noreferrer noopener"
              >
                無料オンライン相談
              </a>
              <p className="hs__contact-cv-note">
                <a
                  href="https://hub.park-lot.com/contact"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="hs__contact-cv-form-link"
                >
                  ※お問い合わせはこちら
                </a>
              </p>
            </div>
            <div className="hs__contact-cv-tel">
              <a href="tel:0368210143">
                <FontAwesomeIcon icon={faPhoneAlt} className="icon" />
                050-3528-8481
              </a>
              <p className="hs__contact-cv-note hs__contact-cv-note--cent">
                受付時間：9:00〜19:00（土日・祝日除く）
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
