import React from "react"
import { AsideLayout } from "components/aside-layout"
import { Seo } from "components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { DefaultHeader } from "components/headers/default-header"
import { Service } from "components/service"
import { FollowBtn } from "components/follow-btn"

const CouponCampaign = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <AsideLayout lang="zh-CN">
        <Seo
          pagetitle="在Twitter即时获胜活动中分发网络优惠券"
          pagepath={location.pathname}
          pagedesc="通过推特活动分发优惠券的目的是既能传播信息，又能吸引电子商务和商店顾客。 本节为那些希望不费吹灰之力、以合理价格吸引客户的负责人解释了活动过程、引入该工具的优势、其丰富的功能和费用。"
        />
        <main className="coupon">
          <section className="fv pt-8 px-4 md:px-11 md:pt-14 relative">
            <div>
              <div className="flex gap-3 mb-3">
                <p className="bg-coup_sec text-center py-2 px-3 font-bold rounded-lg text-coupon md:text-2xl">
                  大大降低了印刷成本
                </p>
                <p className="bg-coup_sec text-center py-2 px-3 font-bold rounded-lg text-coupon md:text-2xl">
                  自动抽奖
                </p>
              </div>
              <h1 className="font-bold text-white mb-3 md:mb-5">
                在社交网站上分发优惠券，
                <br />
                以促进对实体店和
                <br className="md:hidden block" />
                电子商务的访问!
              </h1>
              <p className="font-bold text-white leading-6 md:text-xl md:leading-8 fv-lead">
                通过Twitter活动分发易于
                <br className="md:hidden block" />
                管理的网络优惠券。
                <br />
                你的目标是通过社交网络，既能传播信息，
                <br className="md:hidden block" />
                又能吸引更多的客户!
              </p>
            </div>
            <div className="md:absolute w-9/12 md:w-6/12 md:right-0 md:bottom-0 ml-auto">
              <StaticImage
                src="../../../images/product/coupon/top_coupon.png"
                alt="推特活动易于管理和分发网络优惠券，旨在通过社交网络既传播信息，又吸引更多的客户!"
              />
            </div>
          </section>
          <section className="sec01 bg-white">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="flex flex-col-reverse md:flex-row md:gap-8">
                <div className="md:w-2/5">
                  <StaticImage
                    src="../../../images/product/coupon/sec01.png"
                    alt="更加容易的优惠券活动，提供结果。"
                  />
                </div>
                <div className="md:w-3/5">
                  <h2 className="text-coup_title text-3xl md:text-4xl font-bold mb-4">
                    更加容易的
                    <br />
                    优惠券活动，
                    <br />
                    提供结果。
                  </h2>
                  <p className="text-serv_text">
                    这项服务推荐给那些想把顾客从社交网站引导到实际商店的人，或者那些觉得分发纸质优惠券既费时又困难的人。
                    易于创建和管理的网络优惠券可以在社交网络服务上有效传播，并导致唤起人们对商店的访问。
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="flow">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="text-center mb-8">
                <h2 className="text-coup_title hs__title">Flow</h2>
                <p className="text-serv_subt font-bold text-xl md:pb-6">
                  优惠券发放活动流程
                </p>
              </div>
              <div className="flex md:items-stretch md:gap-16 flex-col md:flex-row gap-y-10 items-center">
                <div className="rounded-lg bg-white p-4 card-arrow w-full">
                  <div className="p-3 text-center">
                    <img
                      layout="constrained"
                      style={{ display: "inline" }}
                      alt="在Twitter上关注并转发该活动"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/step01.png"
                      width={130}
                    />
                  </div>
                  <p className="font-bold text-serv_text">
                    在Twitter上关注并转发该活动
                  </p>
                </div>
                <div className="rounded-lg bg-white p-4 card-arrow w-full">
                  <div className="p-3 text-center">
                    <img
                      layout="constrained"
                      style={{ display: "inline" }}
                      alt="通过DM通知优惠券的网址"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/step02.png"
                      width={130}
                    />
                  </div>
                  <p className="font-bold text-serv_text">
                    通过DM通知优惠券的网址
                  </p>
                </div>
                <div className="rounded-lg bg-white p-4 card-arrow w-full">
                  <div className="p-3 text-center">
                    <img
                      layout="constrained"
                      style={{ display: "inline" }}
                      alt="可以接收和使用优惠券"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/step03.png"
                      width={130}
                    />
                  </div>
                  <p className="font-bold text-serv_text">
                    可以接收和使用优惠券
                  </p>
                </div>
                <div className="rounded-lg bg-white p-4 w-full">
                  <div className="px-8 md:px-0">
                    <img
                      layout="constrained"
                      style={{ display: "inline" }}
                      alt="通过使用优惠券，有望提高知名度，吸引更多客户"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/step04.png"
                      className="mb-8"
                    />
                  </div>
                  <p className="font-bold text-serv_text">
                    通过使用优惠券，有望提高知名度，吸引更多客户
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="merit">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="text-center mb-8">
                <h2 className="text-coup_title hs__title">Merit</h2>
                <p className="text-serv_subt font-bold text-xl md:pb-6">
                  使用PARKLoT的好处
                </p>
              </div>
              <div className="mb-8">
                <h3 className="merit-subtitle text-serv_text text-xl font-bold mb-2 before:content-['01']">
                  每月发放的优惠券数量不限
                </h3>
                <p className="text-serv_text">
                  从每月55,000日元（含税）起，你可以运行你喜欢的活动，性价比很高。
                  <br />
                  两种类型的优惠券使计划设计更具吸引力。
                  纸质优惠券的印刷成本也可以减少。
                </p>
              </div>
              <div className="flex flex-col md:flex-row md:justify-between gap-4 md:gap-8 mb-20">
                <div className="bg-white bg-opacity-40 p-4 rounded-lg w-full">
                  <h4 className="bg-coupon text-center font-bold text-white px-3 py-1 mb-3 rounded">
                    可擦除的优惠券
                  </h4>
                  <div className="text-center mb-3">
                    <img
                      style={{ display: "inline" }}
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/merit01.png"
                      alt="可擦除的优惠券"
                      width={228}
                    />
                  </div>
                  <p className="text-serv_text">
                    在显示优惠券后的一定时间内，商店里的工作人员可以点击屏幕上的一个按钮来确认使用，从而防止多次使用。
                    在经过一定时间后，优惠券会自动使用。
                  </p>
                </div>
                <div className="bg-white bg-opacity-40 p-4 rounded-lg w-full">
                  <h4 className="bg-coupon text-center font-bold text-white px-3 py-1 mb-3 rounded">
                    期间不受限制
                  </h4>
                  <div className="text-center mb-3">
                    <img
                      style={{ display: "inline" }}
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/merit02.png"
                      alt="期间不受限制"
                      width={228}
                    />
                  </div>
                  <p className="text-serv_text">
                    在规定的时间内，可以根据需要多次使用，从而鼓励继续使用
                  </p>
                </div>
              </div>
              <div className="mb-8">
                <h3 className="merit-subtitle merit-subtitle__2line text-serv_text text-xl font-bold mb-2 before:content-['02']">
                  鸣叫发布--抽奖--DMing--优惠券发放，一步到位，无忧无虑{" "}
                </h3>
                <p className="text-serv_text">
                  只需在PARKLoT的管理屏幕上进行设置，活动就会自动按时开始。
                  繁琐的抽签和派发优惠券的工作也可以全部自动化，因此效率非常高。
                  <br />
                  可以举办重复性活动，以吸引更多的客户。
                </p>
              </div>
            </div>
          </section>
          <section className="feature">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="text-center mb-8">
                <h2 className="text-coup_title hs__title">Feature</h2>
                <p className="text-serv_subt font-bold text-xl md:pb-6">职能</p>
              </div>
              <div className="flex flex-wrap gap-4">
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <StaticImage
                        src="../../../images/product/coupon.png"
                        className="md:w-20 w-14"
                        alt="创建优惠券"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      创建优惠券
                    </h3>
                    <p className="text-serv_text">
                      可以创建三种类型的优惠券：可擦除的、定时的和无限期的；也可以显示一个QR码。
                      <br />
                      <span className="font-bold text-coupon">
                        优惠券屏幕显示图像
                      </span>
                      <br />
                      <span className="font-bold text-coupon">
                        只需分享优惠券代码/QR代码/外部URL
                      </span>
                      <br />
                      即可完成设置。
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <StaticImage
                        src="../../../images/product/lottery.png"
                        className="md:w-20 w-14"
                        alt="自动抽奖"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      自动抽奖
                    </h3>
                    <p className="text-serv_text">
                      只需提前设置优惠券类型和中奖人数，就可以从关注和转发的用户中自动抽出优惠券；你还可以设置最大中奖人数，从一个人到所有参与者，并过滤中奖条件，以便更有效地扩散。
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <StaticImage
                        src="../../../images/product/email.png"
                        className="md:w-20 w-14"
                        alt="自动转发"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      自动转发
                    </h3>
                    <p className="text-serv_text">
                      自动抽奖后，还可以自动向获奖者发送DM。
                      即时赢家当场知道结果，使申请人更难离开，更容易引导他们到电子商务网站和实体店。
                      <br />
                      <span className="font-bold text-coupon">19%的回收率</span>
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <StaticImage
                        src="../../../images/product/reserve.png"
                        className="md:w-20 w-14"
                        alt="推特上的保留帖子"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      推特上的保留帖子
                    </h3>
                    <p className="text-serv_text">
                      一旦你从管理界面设置了各种数据，推文就会在指定时间自动发布。
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <StaticImage
                        src="../../../images/product/csv.png"
                        className="md:w-20 w-14"
                        alt="申请人名单CSV数据"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      申请人名单CSV数据
                    </h3>
                    <p className="text-serv_text">
                      可以用CSV格式提供关注公司账户并转发相关推文的申请人的数据。
                      这是回顾和计划下一轮措施的有效方法，因为你也可以检查已经发放的优惠券的使用情况。{" "}
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <StaticImage
                        src="../../../images/product/follower.png"
                        className="md:w-20 w-14"
                        alt="追随者数量管理"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      追随者数量管理
                    </h3>
                    <p className="text-serv_text">
                      管理画面からフォロワーの推移をチャート形式で確認することができます。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="price bg-coup_sec">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="text-center mb-8">
                <h2 className="text-coup_title hs__title">Price</h2>
                <p className="text-serv_subt font-bold text-xl md:pb-6">收费</p>
              </div>
              <div className="flex flex-col gap-4 md:gap-10 md:flex-row">
                <div className="bg-white rounded-lg w-full">
                  <h3 className="bg-coupon text-center rounded-t-lg py-2 font-bold text-white text-xl">
                    基本费率
                  </h3>
                  <div className="p-4 md:p-6">
                    <div className="mb-3">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        初始费用
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coupon text-3xl">80,000</span>
                        日元起
                      </p>
                    </div>
                    <div className="mb-3">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        每月金额
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coupon text-3xl">50,000</span>
                        日元起
                      </p>
                    </div>
                    <div className="mb-5">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        总金额
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coupon text-3xl">130,000</span>
                        日元起(含税143,000日元起)
                      </p>
                    </div>
                    <p className="text-xs text-serv_subt">
                      *也可以实施标签收集和礼品代码赠送活动。
                    </p>
                  </div>
                </div>
                <div className="bg-white rounded-lg w-full">
                  <h3 className="bg-serv_subt text-center rounded-t-lg py-2 font-bold text-white text-xl">
                    选择
                  </h3>
                  <div className="p-4 md:p-6">
                    <div className="mb-3">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        回复收藏
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coup_title text-3xl">30,000</span>
                        日元起
                      </p>
                    </div>
                    <div className="mb-3">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        收集好东西
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coup_title text-3xl">30,000</span>
                        日元起
                      </p>
                    </div>
                    <div>
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        竞选LP创作机构
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coup_title text-3xl">
                          100,000
                        </span>
                        日元起
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <Service />
        </main>
      </AsideLayout>
      <FollowBtn />
    </>
  )
}

export default CouponCampaign
